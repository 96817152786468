import axios from 'axios';
import tokenManager from './tokenManager';

const api = axios.create({
  baseURL: 'https://mekina-gebeya-api.onrender.com/v1',
  // baseURL: 'http://localhost:3001/v1/',

});



api.interceptors.request.use(
  (config) => {
    const token = tokenManager.getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = tokenManager.getRefreshToken();
        const response = await axios.post('/auth/refresh-tokens', { refreshToken });
        tokenManager.setTokens(response.data);
        return api(originalRequest);
      } catch (refreshError) {
        tokenManager.clearTokens();
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export const getBrands = () => api.get('/brand');
export const getCars = (params) => api.get('/cars', { params });
export const createCar = (carData) => api.post('/cars', carData);
export const updateCar = (carId, carData) => api.patch(`/cars/${carId}`, carData);
export const deleteCar = (carId) => api.delete(`/cars/${carId}`);

export default api;