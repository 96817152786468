import React from "react";

export const ChatbubbleEllipses = ({ className }) => {
  return (
    <svg
      className={`chatbubble-ellipses ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.83488 29.6857C6.92863 29.3435 6.72316 28.8677 6.52707 28.5248C6.46628 28.4223 6.40004 28.3232 6.32863 28.2279C4.64681 25.6773 3.7504 22.6893 3.75051 19.6341C3.72238 10.8646 10.9943 3.7482 19.9864 3.7482C27.8286 3.7482 34.3755 9.18101 35.9044 16.3927C36.1339 17.4619 36.2499 18.5523 36.2505 19.6459C36.2505 28.4279 29.2591 35.656 20.2669 35.656C18.8364 35.656 16.9075 35.2966 15.8552 35.0021C14.8029 34.7076 13.7521 34.317 13.481 34.2123C13.2034 34.1057 12.9087 34.0509 12.6114 34.0505C12.2873 34.0494 11.9662 34.114 11.6677 34.2404L6.3677 36.1529C6.25188 36.2029 6.12912 36.2351 6.00363 36.2482C5.90438 36.2479 5.80616 36.2279 5.71468 36.1894C5.62319 36.1509 5.54025 36.0947 5.47065 36.0239C5.40105 35.9531 5.34617 35.8693 5.30918 35.7772C5.2722 35.685 5.25385 35.5865 5.2552 35.4873C5.26171 35.4002 5.27743 35.314 5.30207 35.2302L6.83488 29.6857Z"
        stroke="#011C2F"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.50418"
      />
      <path
        className="path"
        d="M12.5014 22.5031C13.8831 22.5031 15.0032 21.383 15.0032 20.0013C15.0032 18.6195 13.8831 17.4994 12.5014 17.4994C11.1196 17.4994 9.99951 18.6195 9.99951 20.0013C9.99951 21.383 11.1196 22.5031 12.5014 22.5031Z"
        fill="#011C2F"
      />
      <path
        className="path"
        d="M20.0011 22.5031C21.3828 22.5031 22.5029 21.383 22.5029 20.0013C22.5029 18.6195 21.3828 17.4994 20.0011 17.4994C18.6194 17.4994 17.4993 18.6195 17.4993 20.0013C17.4993 21.383 18.6194 22.5031 20.0011 22.5031Z"
        fill="#011C2F"
      />
      <path
        className="path"
        d="M27.5019 22.5031C28.8836 22.5031 30.0037 21.383 30.0037 20.0013C30.0037 18.6195 28.8836 17.4994 27.5019 17.4994C26.1202 17.4994 25.0001 18.6195 25.0001 20.0013C25.0001 21.383 26.1202 22.5031 27.5019 22.5031Z"
        fill="#011C2F"
      />
    </svg>
  );
};