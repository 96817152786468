import React, { useState, useEffect } from "react";
import { ChevronDown } from "../components/home/ChevronDown";
import api from '../utils/axiosInterceptor';
import { Box, Button, MenuItem, Select, Typography, Container, Grid } from '@mui/material';

import { styled } from '@mui/material/styles';
import CarCard from "../components/home/CarCard";
import { Link } from "react-router-dom";
import SpecialOffers from "../components/home/SpecialOfferCard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const FilterSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '50px',
  padding: theme.spacing(4, 6),
  width: '95%',
  // maxWidth: '1200px', // Adjust this value as needed
  margin: '0 auto',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#011c2f',
  color: '#ffffff',
  borderRadius: '10px',
  '& .MuiSelect-select': {
    padding: '15px 20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: '#ffffff',
    fontSize: '1.5rem', // Adjust icon size
    right: '10px', // Position icon
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#efb600',
  color: '#ffffff',
  borderRadius: '12px',
  padding: '15px 40px',
  fontSize: '18px',
  fontWeight: 700,
  '&:hover': {
    backgroundColor: '#d9a400',
  },
}));







const WelcomeSection = styled(Box)(({ theme }) => ({
  // backgroundImage: "url('/home/frame-1000005263.png')",
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  padding: theme.spacing(10),
  color: theme.palette.common.white,
  textAlign: 'center',
}));








const BrandSection = styled(Box)(({ theme }) => ({
  padding: '50px 100px',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.down('md')]: {
    padding: '30px 50px',
  },
}));

const BrandItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  margin: '10px',
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#f0f0f0',
  borderRadius: '50%',
  height: '80px',
  width: '80px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}));

const BrandImage = styled('img')({
  maxWidth: '60%',
  maxHeight: '60%',
  objectFit: 'contain',
});

const BrandName = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontSize: '24px',
  fontWeight: 400,
  letterSpacing: '-0.48px',
  textAlign: 'center',
}));





const ExploreMoreSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: '#f5f5f5',
}));

const ExploreCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  overflow: 'hidden',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const ExploreImage = styled('img')({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
});

const ExploreContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ExploreButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#011c2f',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#03294a',
  },
}));


const Home = () => {
  const [cars, setCars] = useState([]);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState({
    model: '',
    year: '',
    priceRange: '',
  });

  const [availableYears, setAvailableYears] = useState([]);
  const availablePriceRanges = [
    { label: 'Under $10,000', value: '0-10000' },
    { label: '$10,000 - $20,000', value: '10000-20000' },
    { label: 'Above $20,000', value: '20000-100000' },
  ];

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const [carsResponse, modelsResponse, yearsResponse, brandsResponse] = await Promise.all([
        api.get('/cars/all'),
        api.get('/cars/models'),
        api.get('/cars/years'),
        api.get('/brand'),
      ]);
      setCars(carsResponse.data.results);
      setModels(modelsResponse.data);
      setAvailableYears(yearsResponse.data);
      setBrands(brandsResponse.data.results);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const applyFilters = async () => {
    try {
      setLoading(true);
      const response = await api.get('/cars', { params: filters });
      setCars(response.data.results);
    } catch (error) {
      console.error("Error applying filters:", error);
    } finally {
      setLoading(false);
    }
  };


  const exploreCategories = [
    { title: "New Arrivals", image: "/images/new-arrivals.jpg", link: "/new-arrivals" },
    { title: "Best Sellers", image: "/images/best-sellers.jpg", link: "/best-sellers" },
    { title: "Luxury Cars", image: "/images/luxury-cars.jpg", link: "/luxury-cars" },
    { title: "Budget Friendly", image: "/images/budget-friendly.jpg", link: "/budget-friendly" },
  ];





  return (
    <Box sx={{ padding: 0, margin: 0, backgroundColor: 'background.default', width: "100%" }}>
      <Header />
      <Box 
        sx={{ 
          width: "100vw", 
          marginLeft: "calc(-50vw + 50%)",
          marginRight: "calc(-50vw + 50%)",
          marginTop: "20px",
          backgroundImage: `url("/home/frame-1000005263.png")`, 
          backgroundSize: "cover", 
          backgroundPosition: "center" 
        }}
      >
        <WelcomeSection>
          <Typography variant="h2" gutterBottom>
            Welcome to Mekina Gebeya
          </Typography>
          <Typography
          sx={{
            fontSize: '1.2rem',   // Even larger font size
            fontWeight: 500,      // Semi-bold weight
            lineHeight: 1.5,
            // maxWidth: '900px',
            margin: '0 auto',
            textAlign: 'center',
            mt: 3,
            mb: 5,
          }}
          variant="body1">
          Simplify your car buying journey with our user-friendly search, detailed comparisons, 
          </Typography>
          <Typography
          sx={{
            fontSize: '1.2rem',   // Even larger font size
            fontWeight: 500,      // Semi-bold weight
            lineHeight: 1.5,
            // maxWidth: '900px',
            margin: '0 auto',
            textAlign: 'center',
            mt: 3,
            mb: 5,
          }}
          variant="body1">
           And hassle-free purchasing process. Start your adventure today!
          </Typography>
       
        </WelcomeSection>
  
        <BrandSection>
        {brands.map((brand, index) => (
          <BrandItem key={index}>
            <ImageWrapper>
              <BrandImage src={"/home/ferrari.png"}  alt={brand.name} />
            </ImageWrapper>
            <BrandName>{brand.name}</BrandName>
          </BrandItem>
        ))}
      </BrandSection>
  
      <FilterSection>
        <Box textAlign="center" mb={4}>
          <Typography variant="h3" sx={{ color: '#011c2f', fontWeight: 700, mb: 1 }}>
            Purchase Your Car Now
          </Typography>
          <Typography variant="h6" sx={{ color: '#011c2f' }}>
            Discover the perfect car for you!
          </Typography>
        </Box>
        
        <Grid container spacing={3} mb={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" sx={{ color: '#011c2f', fontWeight: 600, mb: 1 }}>
              Model
            </Typography>
            <StyledSelect
              fullWidth
              displayEmpty
              name="model"
              value={filters.model}
              onChange={handleFilterChange}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value="" disabled>Select Vehicle Model</MenuItem>
              {models.map((model) => (
                <MenuItem key={model} value={model}>{model}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" sx={{ color: '#011c2f', fontWeight: 600, mb: 1 }}>
              Year
            </Typography>
            <StyledSelect
              fullWidth
              displayEmpty
              name="year"
              value={filters.year}
              onChange={handleFilterChange}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value="" disabled>Select Model Year</MenuItem>
              {availableYears.map((year) => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle2" sx={{ color: '#011c2f', fontWeight: 600, mb: 1 }}>
              Price Range
            </Typography>
            <StyledSelect
              fullWidth
              displayEmpty
              name="priceRange"
              value={filters.priceRange}
              onChange={handleFilterChange}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value="" disabled>Select Price Range</MenuItem>
              {availablePriceRanges.map((range) => (
                <MenuItem key={range.value} value={range.value}>{range.label}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
        </Grid>
        
        <Box textAlign="center">
          <StyledButton onClick={applyFilters}>
            SEARCH
          </StyledButton>
        </Box>
      </FilterSection>
      
      </Box>
      
      
      <Container maxWidth={false} sx={{ padding: 0, margin: 0, width: "100%" }}>
        <SpecialOffers cars={cars} />
        <CarCard cars={cars} loading={loading} />
      </Container>
      
      <Footer />
    </Box>
  );
};


export default Home;

