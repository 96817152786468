import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Frame = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: '#ffefbe',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  overflow: 'hidden',
  padding: '50px',
  position: 'relative',
}));

const TermsConditions = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontFamily: '"SF Pro Text-Medium", Helvetica',
  fontSize: '32px',
  fontWeight: 500,
  letterSpacing: 0,
  lineHeight: 'normal',
  marginTop: '-1px',
  position: 'relative',
  whiteSpace: 'nowrap',
  width: 'fit-content',
}));

const TextWrapper = styled(Typography)(({ theme }) => ({
  alignSelf: 'stretch',
  color: '#000000',
  fontFamily: '"SF Pro Text-Regular", Helvetica',
  fontSize: '20px',
  fontWeight: 400,
  letterSpacing: 0,
  lineHeight: 'normal',
  position: 'relative',
}));

const Div = styled(Typography)(({ theme }) => ({
  alignSelf: 'stretch',
  color: '#000000',
  fontFamily: '"SF Pro Text-Regular", Helvetica',
  fontSize: '20px',
  fontWeight: 400,
  letterSpacing: 0,
  lineHeight: 'normal',
  marginBottom: '-50px',
  position: 'relative',
}));

const TermsAndConditions = () => {
  return (
    <Frame>
      <TermsConditions>Terms &amp; Conditions</TermsConditions>
      <TextWrapper>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
      </TextWrapper>
      <TextWrapper>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
      </TextWrapper>
      <TextWrapper>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
      </TextWrapper>
      <TextWrapper>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
      </TextWrapper>
      <Div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
      </Div>
      <Div>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.
      </Div>
    </Frame>
  );
};
export default TermsAndConditions;