import authService from '../../services/authService';
import tokenManager from '../../utils/tokenManager';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const login = (credentials, rememberMe) => async (dispatch) => {
  try {
    const response = await authService.login(credentials);
    tokenManager.setTokens(response.data.tokens);
    tokenManager.setRememberMe(rememberMe);
    dispatch({ type: LOGIN_SUCCESS, payload: response.data.user });
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE, payload: error.response.data.message });
  }
};

export const signup = (userData) => async (dispatch) => {
  try {
    const response = await authService.signup(userData);
    dispatch({ type: SIGNUP_SUCCESS, payload: response.data.user });
  } catch (error) {
    dispatch({ type: SIGNUP_FAILURE, payload: error.response.data.message });
  }
};

export const logout = () => async (dispatch) => {
  await authService.logout();
  tokenManager.clearTokens();
  dispatch({ type: LOGOUT });
};