import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import { Contour } from "./Contour";

const theme = {
  primary: "#011c2f",
  secondary: "#149b09",
  accent: "#EFB600",
  background: "#f5f5f5",
  text: "#454a53",
  white: "#ffffff",
};

const Frame12 = styled(Box)(({ theme }) => ({
  width: "50%",
  padding: "20px",
  backgroundColor: theme.white,
  borderRadius: "15px",
  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  [theme.breakpoints.down('md')]: {
    width: "100%",
  },
}));

const StyledTypography = styled(Typography)({
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "32px",
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: "normal",
  marginTop: "-1px",
  position: "relative",
});

const Frame14 = styled(Box)({
  alignItems: "center",
  alignSelf: "stretch",
  backgroundColor: theme.background,
  borderRadius: "15px",
  display: "flex",
  justifyContent: "space-between",
  padding: "20px",
  position: "relative",
  width: "100%",
});

const Frame15 = styled(Box)({
  alignItems: "flex-start",
  display: "inline-flex",
  flexDirection: "column",
  position: "relative",
});

const PriceTypography = styled(Typography)({
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "32px",
  fontWeight: 700,
  letterSpacing: 0,
  lineHeight: "normal",
  position: "relative",
  whiteSpace: "nowrap",
  width: "fit-content",
});

const StyledButton = styled(Button)({
  alignItems: "center",
  backgroundColor: theme.accent,
  borderRadius: "10px",
  boxShadow: "0px 3px 15px #00000026",
  display: "inline-flex",
  gap: "10px",
  justifyContent: "center",
  padding: "15px 20px",
  position: "relative",
  cursor: "pointer",
  color: theme.white,
  fontWeight: "bold",
  transition: "all 0.3s ease",
  '&:hover': {
    backgroundColor: "#d9a400",
    transform: "translateY(-2px)",
  },
});

const InspectionBadge = styled(Box)(({ isApproved }) => ({
  alignItems: "left",
  backgroundColor: isApproved ? theme.secondary : theme.accent,
  borderRadius: "10px",
  display: "inline-flex",
  gap: "5px",
  justifyContent: "center",
  padding: "15px 20px",
  position: "relative",
  width: "250px",
}));

const BadgeText = styled(Typography)({
  color: theme.white,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "normal",
});

const DescriptionTitle = styled(Typography)({
  alignSelf: "stretch",
  color: theme.primary,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: 500,
  position: "relative",
});

const DescriptionTypography = styled(Typography)({
  alignSelf: "stretch",
  color: theme.text,
  fontFamily: "'Poppins', sans-serif",
  fontSize: "20px",
  fontWeight: 400,
  letterSpacing: "-0.15px",
  lineHeight: "30px",
  position: "relative",
});

const Frame12MatchingFrame18 = ({ car }) => (
  <Frame12>
    <StyledTypography>{`${car.brand.name}: ${car.year}`}</StyledTypography>
    <StyledTypography>{car.model}</StyledTypography>
    <Frame14>
      <Frame15>
        <Typography variant="body1">Price</Typography>
        <PriceTypography>{`${car.price.toLocaleString()} ETB`}</PriceTypography>
      </Frame15>
      <StyledButton>
        <Typography variant="body1">Make an offer</Typography>
      </StyledButton>
    </Frame14>
    <InspectionBadge isApproved={car.isApproved}>
      <BadgeText>{car.isApproved ? "Inspection Passed" : "Pending Inspection"}</BadgeText>
      <Contour className="general-check-circle" />
    </InspectionBadge>
    <DescriptionTitle>Description</DescriptionTitle>
    <DescriptionTypography>
      {car.additionalFeatures.join(", ")}
    </DescriptionTypography>
  </Frame12>
);

export default Frame12MatchingFrame18;