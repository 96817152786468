import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PhoneVerification from './pages/PhoneVerification';
import CarListing from './pages/CarListing';
import Home from './pages/Home';
import Scheduling from './pages/Scheduling';
import Profile from './pages/Profile';
const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-otp" element={<PhoneVerification />} />
        <Route path="/home" element={<Home />} />
        <Route path="/car-listing/:id" element={<CarListing />} />
        <Route path="/scheduling" element={<Scheduling />} />
        <Route path="/profile" element={<Profile />} />
        
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate to="/car-listing" />} />
      </Routes>
    </Router>
  );
};

export default App;