import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Appstore } from "./carListing/Appstore";
import { Playstore } from "./carListing/Playstore";
import useMediaQuery from "@mui/material/useMediaQuery";

const FrameWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#011c2f',
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  justifyContent: 'center',
  padding: '50px 20px',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    padding: '50px 200px',
  },
}));

const FrameContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  gap: '30px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(/listings/frame-1000005268.png)',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  height: '100px',
  width: '250px',
  [theme.breakpoints.up('sm')]: {
    height: '157px',
    width: '384px',
  },
}));

const ColumnWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontFamily: 'Poppins-Regular, Helvetica',
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: '20px',
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'column',
    gap: '50px',
  },
}));

const StyledButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  gap: '10px',
  padding: '10px 15px',
  [theme.breakpoints.up('sm')]: {
    padding: '15px 20px',
  },
}));

const LineWrapper = styled(Box)(({ theme }) => ({
  alignSelf: 'stretch',
  padding: '10px',
  width: '100%',
}));

const StyledLine = styled(Box)(({ theme }) => ({
  alignSelf: 'stretch',
  height: '1px',
  width: '100%',
  objectFit: 'cover',
  backgroundColor: '#5F6368',
}));

const Footer = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'));

  return (
    <FrameWrapper>
      <FrameContent>
        <ImageBox />
        <ColumnWrapper>
          <StyledText>Contact Us</StyledText>
          <StyledText>Terms & Conditions</StyledText>
          <StyledText>Support Center</StyledText>
        </ColumnWrapper>
        <ColumnWrapper>
          <StyledText>Facebook</StyledText>
          <StyledText>Instagram</StyledText>
          <StyledText>TikTok</StyledText>
        </ColumnWrapper>
        <ButtonContainer>
          <StyledButton>
            <Playstore className="icon-instance-node" />
            <Typography sx={{ color: '#000000', fontSize: isMobile ? '14px' : '16px' }}>
              Get on Playstore
            </Typography>
          </StyledButton>
          <StyledButton>
            <Appstore className="icon-instance-node" />
            <Typography sx={{ color: '#000000', fontSize: isMobile ? '14px' : '16px' }}>
              Get on Appstore
            </Typography>
          </StyledButton>
        </ButtonContainer>
      </FrameContent>
      <LineWrapper>
        <StyledLine />
      </LineWrapper>
      <Typography sx={{ color: '#ffffff', fontSize: isMobile ? '12px' : '14px', textAlign: 'center' }}>
        Mekina Gebeya 2024 © All Rights Reserved
      </Typography>
    </FrameWrapper>
  );
};

export default Footer;