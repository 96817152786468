import React from "react";

export const Notifications = ({ className }) => {
  return (
    <svg
      className={`notifications ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M33.4118 27.454C31.4055 24.9985 29.9891 23.7485 29.9891 16.979C29.9891 10.7798 26.8235 8.57119 24.218 7.49854C23.8719 7.35635 23.5462 7.02979 23.4407 6.67432C22.9837 5.11885 21.7024 3.74854 19.9993 3.74854C18.2962 3.74854 17.0141 5.11963 16.5618 6.67588C16.4563 7.03526 16.1305 7.35635 15.7844 7.49854C13.1759 8.57276 10.0134 10.7735 10.0134 16.979C10.0094 23.7485 8.59304 24.9985 6.58679 27.454C5.75554 28.4712 6.48366 29.9985 7.93757 29.9985H32.0688C33.5149 29.9985 34.2384 28.4665 33.4118 27.454Z"
        stroke="black" 
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.50418"
      />
      <path
        className="path"
        d="M25.0006 29.9983V31.2485C25.0006 32.5747 24.4737 33.8467 23.5359 34.7845C22.5981 35.7223 21.3261 36.2492 19.9999 36.2492C18.6736 36.2492 17.4016 35.7223 16.4638 34.7845C15.526 33.8467 14.9991 32.5747 14.9991 31.2485V29.9983"
        stroke="black" 
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.50418"
      />
    </svg>
  );
};
