import React from "react";

export const Call = ({ className }) => {
  return (
    <svg
      className={`call ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M26.4264 21.9146C25.4959 20.9771 23.2424 19.6089 22.149 19.0576C20.7252 18.3404 20.608 18.2818 19.4889 19.1132C18.7424 19.6681 18.2461 20.1638 17.3724 19.9775C16.4988 19.7912 14.6004 18.7406 12.9381 17.0835C11.2758 15.4265 10.1642 13.473 9.97733 12.6023C9.79042 11.7316 10.2943 11.2412 10.8439 10.4929C11.6185 9.43823 11.56 9.26245 10.8978 7.83862C10.3816 6.7312 8.97362 4.49878 8.03261 3.573C7.02597 2.57866 7.02597 2.75444 6.37734 3.02397C5.84927 3.24615 5.34266 3.51623 4.86386 3.83081C3.92636 4.45366 3.40605 4.97104 3.04218 5.74858C2.67831 6.52612 2.51484 8.34897 4.39394 11.7626C6.27304 15.1763 7.5914 16.9218 10.3201 19.6429C13.0488 22.364 15.1471 23.8271 18.2144 25.5474C22.009 27.6726 23.4644 27.2584 24.2443 26.8951C25.0242 26.5318 25.5439 26.0162 26.168 25.0787C26.4833 24.6007 26.754 24.0946 26.9765 23.5669C27.2467 22.9207 27.4224 22.9207 26.4264 21.9146Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="1.87814"
      />
    </svg>
  );
};