import React from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const StyledFrame = styled.div`
  background-color: #f8f9fa;
  padding: 60px 20px;
  @media (min-width: 768px) {
    padding: 80px 40px;
  }
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
`;

const OffersContainer = styled.div`
  display: grid;
  gap: 30px;
  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const OfferCard = styled.div`
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 250px;
  overflow: hidden;
`;

const OfferImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${OfferCard}:hover & {
    transform: scale(1.05);
  }
`;

const DiscountBadge = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #efb600;
  color: #fff;
  padding: 8px 16px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 18px;
`;

const ContentContainer = styled.div`
  padding: 30px;
`;

const CarTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Price = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: #efb600;
`;

const OriginalPrice = styled.span`
  font-size: 18px;
  color: #999;
  text-decoration: line-through;
`;

const ViewDetailsButton = styled(Link)`
  display: inline-block;
  background-color: #011c2f;
  color: #fff;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #023e6a;
  }
`;

const ExploreMore = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #011c2f;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SpecialOffers = ({ cars }) => {
  return (
    <StyledFrame>
      <SectionHeader>
        <Title>Special Offers</Title>
        <Subtitle>Discover amazing deals on our top-rated vehicles. Don't miss out on these limited-time offers!</Subtitle>
      </SectionHeader>
      <OffersContainer>
        {cars.slice(0, 2).map((car, index) => (
          <OfferCard key={car._id}>
            <ImageContainer>
              <OfferImage src={car.thumbnail || "/default-car-image.jpg"} alt={car.model} />
              <DiscountBadge>{index === 0 ? '35% OFF' : '25% OFF'}</DiscountBadge>
            </ImageContainer>
            <ContentContainer>
              <CarTitle>{`${car.brand?.name} ${car.model}`}</CarTitle>
              <Description>
                Experience luxury and performance with this incredible offer. Limited time only!
              </Description>
              <PriceContainer>
                <Price>${(car.price * (index === 0 ? 0.65 : 0.75)).toFixed(2)}</Price>
                <OriginalPrice>${car.price.toFixed(2)}</OriginalPrice>
              </PriceContainer>
              <ViewDetailsButton to={`/car-listing/${car.id}`}>View Details</ViewDetailsButton>
            </ContentContainer>
          </OfferCard>
        ))}
      </OffersContainer>
      <ExploreMore to="/special-offers">
        Explore More Offers <ArrowForwardIcon style={{ marginLeft: '8px' }} />
      </ExploreMore>
    </StyledFrame>
  );
};

export default SpecialOffers;