import React, { useState, useEffect, useRef, useCallback } from "react";
import { styled } from "@mui/material/styles";
import ReactDOM from 'react-dom';
import { 
  InputBase, Box, List, ListItem, Typography, 
  Drawer, IconButton, FormGroup, FormControlLabel, Checkbox, Button, CircularProgress, Slider, Select, MenuItem
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import api from "../../utils/axiosInterceptor";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";

const SearchWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "60px",
  [theme.breakpoints.down("sm")]: {
    height: "50px",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: "10px",
    width: "100%",
    fontSize: "24px",
    color: "#7c7c7c",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
}));

const SearchIcon = styled("svg")(({ theme }) => ({
  width: "33px",
  height: "33px",
  [theme.breakpoints.down("sm")]: {
    width: "24px",
    height: "24px",
  },
}));

const ResultsDropdown = styled(List)(({ theme }) => ({
  position: "absolute",
  zIndex: 1000,
  width: "100%",
  maxHeight: "500px",
  overflowY: "auto",
  backgroundColor: "#fff",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "0 0 15px 15px",
  padding: theme.spacing(1, 0),
}));

const ResultItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  cursor: "pointer",
  transition: "background-color 0.2s",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

const ThumbnailWrapper = styled(Box)({
  width: "80px",
  height: "80px",
  marginRight: "16px",
  borderRadius: "8px",
  overflow: "hidden",
});

const Thumbnail = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const InfoWrapper = styled(Box)({
  flex: 1,
});

const FilterDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: 300,
    padding: 20,
  },
});

export const Search = ({ className, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoadingResult, setIsLoadingResult] = useState(false);
  
  const [selectedFields, setSelectedFields] = useState({
    model: true,
    carType: false,
    country: false,
    year: false,
    fuelType: false,
    brand: false,
    userFullName: false,
  });
  const [filterOptions, setFilterOptions] = useState({
    priceRange: [1000, 100000],
    carType: "All",
  });
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const [loadingPosition, setLoadingPosition] = useState({ top: 0, left: 0 });
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const clickedItemRef = useRef(null);

  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (term.length > 2) {
        setIsLoadingResult(true);
        try {
          const params = {};
          Object.entries(selectedFields).forEach(([field, isSelected]) => {
            if (isSelected) {
              params[field] = term;
            }
          });

          const response = await api.get("/cars", { params });
          setSearchResults(response?.data?.results || []);
          setIsDropdownVisible(true);
        } catch (error) {
          console.error("Error fetching search results:", error);
        }
        setIsLoadingResult(false);
      } else {
        setSearchResults([]);
        setIsDropdownVisible(false);
      }
    }, 300),
    [selectedFields]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
      setLoadingPosition({
        top: rect.top + window.scrollY + rect.height / 2 - 20,
        left: rect.left + window.scrollX + rect.width - 40,
      });
    }
  }, [searchTerm, inputRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setTimeout(() => {
          if (!clickedItemRef.current) {
            setIsDropdownVisible(false);
          }
          clickedItemRef.current = null;
        }, 100);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFieldChange = (event) => {
    setSelectedFields({
      ...selectedFields,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSelectVehicle = (vehicleId) => {
    console.log("handleSelectVehicle called with:", vehicleId);
    clickedItemRef.current = true;
    navigate(`/car-listing/${vehicleId}`);
    onSearch(vehicleId);
    setSearchResults([]);
    setSearchTerm("");
    setIsDropdownVisible(false);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(price);
  };

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const renderSearchResults = () => {
    if (!isDropdownVisible) return null;

    return ReactDOM.createPortal(
      <ResultsDropdown
        style={{
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          width: dropdownPosition.width,
        }}
      >
        {searchResults.length === 0 ? (
          <Typography variant="body2" sx={{ p: 2, textAlign: "center" }}>
            No results found
          </Typography>
        ) : (
          searchResults.map((vehicle) => (
            <ResultItem
              key={vehicle.id}
              onMouseDown={() => clickedItemRef.current = true}
              onClick={() => handleSelectVehicle(vehicle.id)}
            >
              <ThumbnailWrapper>
                <Thumbnail
                  src={vehicle.thumbnail}
                  alt={`${vehicle.brand?.name} ${vehicle.model}`}
                />
              </ThumbnailWrapper>
              <InfoWrapper>
                <Typography variant="subtitle1" component="h3" color="textSecondary">
                  {vehicle.brand?.name} {vehicle.model} ({vehicle.year})
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatPrice(vehicle.price)} • {vehicle.mileage} miles
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {vehicle.fuelType} • {vehicle.transmissionType} • {vehicle.condition}
                </Typography>
              </InfoWrapper>
            </ResultItem>
          ))
        )}
      </ResultsDropdown>,
      document.body
    );
  };

  // const renderLoadingSpinner = () => {
  //   if (!isLoadingResult) return null;

  //   return ReactDOM.createPortal(
  //     <Box
  //       position="absolute"
  //       style={{ top: loadingPosition.top, left: loadingPosition.left }}
  //     >
  //       <CircularProgress size={24} />
  //     </Box>,
  //     document.body
  //   );
  // };

  const renderLoadingSpinner = () => {
    if (!isLoadingResult) return null;

    return (
      <Box
        position="absolute"
        style={{
          top: '50%',
          right: '4px',
          transform: 'translateY(-50%)',
        }}
      >
        <CircularProgress size={35} />
      </Box>
    );
  };


  const renderFilterDrawer = () => (
    <FilterDrawer anchor="right" open={isFilterDrawerOpen} onClose={toggleFilterDrawer}>
      <Typography variant="h6" gutterBottom>
        Search Filters
      </Typography>
      <FormGroup>
        <Typography variant="subtitle1">Basic Filters</Typography>
        {Object.entries(selectedFields).map(([field, isChecked]) => (
          <FormControlLabel
            key={field}
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleFieldChange}
                name={field}
              />
            }
            label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, " $1").trim()}
          />
        ))}

        <Typography variant="subtitle1">Price Range</Typography>
        <Slider
          value={filterOptions.priceRange}
          onChange={(e, newValue) => setFilterOptions({ ...filterOptions, priceRange: newValue })}
          valueLabelDisplay="auto"
          min={1000}
          max={100000}
          step={1000}
          marks={[
            { value: 1000, label: "$1k" },
            { value: 50000, label: "$50k" },
            { value: 100000, label: "$100k" }
          ]}
        />

        <Typography variant="subtitle1">Car Type</Typography>
        <Select
          value={filterOptions.carType}
          onChange={(e) => setFilterOptions({ ...filterOptions, carType: e.target.value })}
          displayEmpty
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="SUV">SUV</MenuItem>
          <MenuItem value="Sedan">Sedan</MenuItem>
          <MenuItem value="Truck">Truck</MenuItem>
          <MenuItem value="Coupe">Coupe</MenuItem>
        </Select>
      </FormGroup>
      <Button variant="contained" color="primary" onClick={toggleFilterDrawer} style={{ marginTop: 20 }}>
        Apply Filters
      </Button>
    </FilterDrawer>
  );

  return (
    <SearchWrapper ref={inputRef}>
      <InputWrapper>
        <SearchIcon
          className={`search ${className}`}
          fill="none"
          viewBox="0 0 33 33"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="path"
            d="M14.25 4.12711C12.2475 4.12711 10.2899 4.72092 8.62491 5.83346C6.95988 6.946 5.66214 8.52729 4.89581 10.3774C4.12948 12.2275 3.92897 14.2632 4.31965 16.2273C4.71032 18.1913 5.67462 19.9954 7.09061 21.4114C8.50661 22.8274 10.3107 23.7917 12.2747 24.1824C14.2388 24.573 16.2746 24.3725 18.1246 23.6062C19.9747 22.8399 21.556 21.5421 22.6686 19.8771C23.7811 18.2121 24.3749 16.2545 24.3749 14.252C24.3747 11.5668 23.308 8.99156 21.4092 7.09281C19.5104 5.19406 16.9352 4.12728 14.25 4.12711V4.12711Z"
            stroke="#7D7D7D"
            strokeMiterlimit="10"
            strokeWidth="2.00378"
          />
          <path
            className="path"
            d="M21.803 21.806L28.877 28.88"
            stroke="#7D7D7D"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.00378"
          />
        </SearchIcon>
        <StyledInputBase
          placeholder="Search vehicles..."
          inputProps={{ "aria-label": "search" }}
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => searchResults.length > 0 && setIsDropdownVisible(true)}
        />
        <IconButton onClick={toggleFilterDrawer}>
          <FilterListIcon />
        </IconButton>
      </InputWrapper>
      {renderSearchResults()}
      {renderLoadingSpinner()}
      {renderFilterDrawer()}
    </SearchWrapper>
  );
};

export default Search;