import React from "react";

export const Contour2 = ({ className }) => {
  return (
    <svg
      className={`contour-2 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_1_487)">
        <path
          className="path"
          clipRule="evenodd"
          d="M6.7892 2.36667C7.26575 1.33351 8.73413 1.3335 9.21069 2.36667L10.518 5.20096L13.6176 5.56846C14.7474 5.70242 15.2012 7.09894 14.3659 7.87143L12.0743 9.99062L12.6826 13.052C12.9043 14.168 11.7164 15.0311 10.7235 14.4754L7.99994 12.9508L5.27634 14.4753C4.28352 15.0311 3.09557 14.168 3.31731 13.052L3.92561 9.99062L1.63403 7.87143C0.7987 7.09894 1.25245 5.70242 2.38231 5.56846L5.48187 5.20096L6.7892 2.36667ZM9.30727 5.75942L7.99994 2.92513L6.69261 5.75942C6.49839 6.18049 6.09934 6.47042 5.63886 6.52502L2.5393 6.89252L4.83088 9.01171C5.17133 9.32655 5.32375 9.79565 5.23338 10.2505L4.62508 13.3119L7.34869 11.7873C7.75332 11.5608 8.24657 11.5608 8.6512 11.7873L11.3748 13.3119L10.7665 10.2505C10.6761 9.79565 10.8286 9.32654 11.169 9.01171L13.4606 6.89252L10.361 6.52502C9.90055 6.47042 9.5015 6.18049 9.30727 5.75942Z"
          fill="#FBB03B"
          fillRule="evenodd"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_1_487">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};