import React from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import LoadingAnimation from '../LoadingAnimation';

const StyledFrame = styled.div`
  background-color: #ffffff;
  padding: 40px 20px;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const HeaderContent = styled.div`
  flex: 1;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const MainTitle = styled.h1`
  color: #000000;
  font-family: "SF Pro Text-Semibold", Helvetica;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 40px;
  }
`;

const MainDescription = styled.p`
  color: #000000;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  max-width: 100%;

  @media (min-width: 768px) {
    font-size: 20px;
    max-width: 60%;
  }
`;

const ExploreMore = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

const ExploreMoreText = styled.span`
  color: #000000;
  font-family: "SF Pro Text-Semibold", Helvetica;
`;

const CarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
  }
`;

const CarContainer = styled(Link)`
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  text-decoration: none;
  aspect-ratio: 16 / 9;
`;

const CarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CarTitle = styled.h2`
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-family: "Inter-Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.6);

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const CarCard = ({ cars, loading }) => {
  return (
    <StyledFrame>
      <HeaderSection>
        <HeaderContent>
          <MainTitle>All Vehicles For Sale</MainTitle>
          <MainDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida eget ultricies pharetra
            scelerisque duis cursus. Lorem ipsum dolor sit amet.
          </MainDescription>
        </HeaderContent>
        <ExploreMore>
          <ExploreMoreText>Explore More</ExploreMoreText>
          <ArrowForwardIcon style={{fontSize: "inherit", fontWeight: 600}}/>
        </ExploreMore>
      </HeaderSection>

      {loading ? (
        <LoadingAnimation />
      ) : (
        <CarGrid>
          {cars.map((car) => (
            <CarContainer key={car._id} to={`/car-listing/${car.id}`}>
              <CarImage
                src={car.thumbnail || "/api/placeholder/400/360"}
                alt={car.model || 'Car'}
              />
              <CarTitle>
                {car.category || `${car.brand?.name} ${car.model}`}
              </CarTitle>
            </CarContainer>
          ))}
        </CarGrid>
      )}
    </StyledFrame>
  );
};

export default CarCard;