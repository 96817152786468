import React from 'react';
import { keyframes } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import { DirectionsCar } from '@mui/icons-material';

const drive = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const LoadingAnimation = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '200px',
          height: '60px',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '2px',
            backgroundColor: '#011c2f',
          }}
        />
        <DirectionsCar
          sx={{
            position: 'absolute',
            bottom: '2px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#EFB600',
            fontSize: '48px',
            animation: `${drive} 2s linear infinite, ${bounce} 0.5s ease-in-out infinite`,
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          mt: 2,
          ml: 7,
          fontFamily: "'Poppins', sans-serif",
          color: '#011c2f',
          animation: `${bounce} 1s ease-in-out infinite`,
        }}
      >
        Loading...
      </Typography>
    </Box>
  );
};

export default LoadingAnimation;