import React from "react";

export const Playstore = ({ className }) => {
  return (
    <svg
      className={`playstore ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M2.81238 3.48681V26.5142C2.81253 26.5641 2.82743 26.6129 2.85521 26.6544C2.88299 26.696 2.92241 26.7284 2.96853 26.7476C3.01465 26.7668 3.06541 26.772 3.11445 26.7624C3.1635 26.7529 3.20864 26.7291 3.24421 26.694L15.2343 15.0011L3.24421 3.30693C3.20864 3.27186 3.1635 3.24806 3.11445 3.23854C3.06541 3.22902 3.01465 3.23418 2.96853 3.25339C2.92241 3.2726 2.88299 3.305 2.85521 3.34653C2.82743 3.38805 2.81253 3.43686 2.81238 3.48681Z"
        fill="black"
      />
      <path
        className="path"
        d="M20.2624 10.1965L5.22843 1.91365L5.21905 1.90838C4.96007 1.76775 4.71398 2.11814 4.92609 2.32205L16.7111 13.5908L20.2624 10.1965Z"
        fill="black"
      />
      <path
        className="path"
        d="M4.92726 27.6793C4.71398 27.8833 4.96007 28.2336 5.22023 28.093L5.2296 28.0877L20.2624 19.8049L16.711 16.4094L4.92726 27.6793Z"
        fill="black"
      />
      <path
        className="path"
        d="M26.3314 13.5353L22.1326 11.2229L18.1846 15.0004L22.1326 18.7761L26.3314 16.4654C27.4736 15.8343 27.4736 14.1665 26.3314 13.5353Z"
        fill="black"
      />
    </svg>
  );
};