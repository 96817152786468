import React from "react";

export const Tiktok = ({ className }) => {
  return (
    <svg
      className={`tiktok ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M24.152 6.95151C23.9621 6.8534 23.7773 6.74584 23.5983 6.62925C23.0775 6.28498 22.6001 5.87934 22.1762 5.42104C21.1156 4.20757 20.7196 2.97651 20.5737 2.1146H20.5795C20.4576 1.39917 20.508 0.936279 20.5156 0.936279H15.6852V19.6148C15.6852 19.8656 15.6852 20.1134 15.6746 20.3583C15.6746 20.3888 15.6717 20.4169 15.6699 20.4498C15.6699 20.4632 15.6699 20.4773 15.667 20.4914C15.667 20.4949 15.667 20.4984 15.667 20.5019C15.6161 21.1721 15.4013 21.8195 15.0414 22.3872C14.6816 22.9548 14.1877 23.4254 13.6033 23.7574C12.9943 24.1039 12.3054 24.2856 11.6047 24.2847C9.35412 24.2847 7.5301 22.4496 7.5301 20.1832C7.5301 17.9167 9.35412 16.0816 11.6047 16.0816C12.0307 16.0812 12.4541 16.1482 12.8592 16.2802L12.8651 11.3619C11.6354 11.203 10.3861 11.3008 9.19613 11.6489C8.00612 11.997 6.90118 12.588 5.95099 13.3845C5.11841 14.1079 4.41846 14.9711 3.88263 15.9351C3.67873 16.2867 2.90939 17.6994 2.81623 19.9921C2.75763 21.2935 3.14845 22.6417 3.33478 23.199V23.2107C3.45197 23.5388 3.90607 24.6585 4.64611 25.6025C5.24285 26.3597 5.94788 27.0248 6.73849 27.5765V27.5648L6.75021 27.5765C9.08869 29.1656 11.6815 29.0613 11.6815 29.0613C12.1303 29.0431 13.6338 29.0613 15.3412 28.2521C17.235 27.355 18.3131 26.0185 18.3131 26.0185C19.0019 25.2199 19.5495 24.3098 19.9326 23.3273C20.3697 22.1783 20.5156 20.8001 20.5156 20.2494V10.34C20.5742 10.3751 21.3547 10.8914 21.3547 10.8914C21.3547 10.8914 22.4791 11.6121 24.2334 12.0814C25.492 12.4154 27.1877 12.4857 27.1877 12.4857V7.69038C26.5936 7.75483 25.3871 7.56733 24.152 6.95151Z"
        fill="black"
      />
    </svg>
  );
};