// components/pages/Settings/index.jsx
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import UpdateProfile from '../../components/settings/UpdateProfile';
import VehiclePreference from '../../components/settings/VehiclePreference';
import LanguagePreference from '../../components/settings/LanguagePreference';
import TermsAndConditions from '../../components/settings/ndConidtion';


const MenuContainer = styled('div')(({ theme }) => ({
  width: '350px',
  backgroundColor: theme.white,
  borderRadius: '16px',
  padding: '16px',
  marginBottom: '24px',
  height: 'fit-content',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const ContentContainer = styled('div')({
  flex: 1,
  backgroundColor: 'white',
  borderRadius: '16px',
  height: '700px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
});

const ContentScroll = styled('div')({
  flex: 1,
  padding: '24px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '3px',
  },
});

const MenuItem = styled('button')(({ isSelected }) => ({
  width: '100%',
  padding: '16px',
  borderRadius: '12px',
  border: 'none',
  backgroundColor: isSelected ? '#EFB600' : 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: isSelected ? '#EFB600' : 'rgba(239, 182, 0, 0.1)',
  },
}));

const Settings = () => {
  const [selectedPage, setSelectedPage] = useState('update-profile');





// SVG components
const IconChat = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.99997 12C8.99997 11.4477 8.55225 11 7.99997 11C7.44768 11 6.99997 11.4477 6.99997 12C6.99997 12.5523 7.44768 13 7.99997 13C8.55225 13 8.99997 12.5523 8.99997 12Z" fill="#011C2F"/>
    <path d="M12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11Z" fill="#011C2F"/>
    <path d="M17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13C16.5523 13 17 12.5523 17 12Z" fill="#011C2F"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 13.8214 21.513 15.5291 20.6621 17L21.2345 20.757C21.3514 21.5248 20.5867 22.127 19.8678 21.8334L17 20.6622C15.5291 21.513 13.8214 22 12 22C6.47712 22 1.99997 17.5228 1.99997 12C1.99997 6.47715 6.47712 2 12 2C17.5228 2 22 6.47715 22 12ZM18.9309 15.9985C18.7032 16.3922 18.6165 16.8516 18.685 17.3012L18.9918 19.3153L17.7561 18.8106C17.1832 18.5766 16.5342 18.6211 15.9985 18.931C14.8239 19.6105 13.4604 20 12 20C7.58169 20 3.99997 16.4183 3.99997 12C3.99997 7.58172 7.58169 4 12 4C16.4182 4 20 7.58172 20 12C20 13.4605 19.6104 14.8239 18.9309 15.9985Z" fill="#011C2F"/>
  </svg>
);

const IconInfo = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69807 17.3019 2.4 12 2.4C6.69807 2.4 2.4 6.69807 2.4 12C2.4 17.3019 6.69807 21.6 12 21.6ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#011C2F"/>
    <path d="M10.8 7.2C10.8 6.53726 11.3373 6 12 6C12.6627 6 13.2 6.53726 13.2 7.2C13.2 7.86274 12.6627 8.4 12 8.4C11.3373 8.4 10.8 7.86274 10.8 7.2Z" fill="#011C2F"/>
    <path d="M13.3961 16.5036C13.4523 17.3127 12.8111 18 12 18C11.1889 18 10.5477 17.3127 10.6039 16.5036L10.9307 11.7976C10.9697 11.2358 11.4369 10.8 12 10.8C12.5632 10.8 13.0303 11.2358 13.0693 11.7976L13.3961 16.5036Z" fill="#011C2F"/>
  </svg>
);

const IconText = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.73242 18H4.36377L5.26855 15.0947H9.50195L10.4067 18H13.0381L8.86279 6.02197H5.91602L1.73242 18ZM7.36035 8.34619H7.41016L8.9209 13.2188H5.84961L7.36035 8.34619Z" fill="#011C2F"/>
    <path d="M16.5742 18.1411C17.7446 18.1411 18.6909 17.585 19.1973 16.6719H19.2471V18H21.6709V11.8989C21.6709 10.106 20.1851 8.91895 17.8857 8.91895C15.5366 8.91895 14.1255 10.0977 13.9927 11.7412L13.9844 11.8408H16.2007L16.2173 11.7661C16.3335 11.1934 16.8813 10.77 17.7861 10.77C18.7324 10.77 19.2471 11.2598 19.2471 12.0483V12.5962L17.0474 12.729C14.8394 12.8701 13.5859 13.8164 13.5859 15.4351V15.4517C13.5859 17.0205 14.7896 18.1411 16.5742 18.1411ZM15.9849 15.3271V15.3105C15.9849 14.6797 16.4746 14.2812 17.4126 14.2231L19.2471 14.1069V14.7461C19.2471 15.6758 18.4502 16.373 17.3628 16.373C16.5244 16.373 15.9849 15.9829 15.9849 15.3271Z" fill="#011C2F"/>
    <path d="M1.5 21.1128H22.4429V22.6899H1.5V21.1128Z" fill="#011C2F"/>
  </svg>
);

const IconChevron = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 18L15 12L9 6" stroke="#011C2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);


  const menuItems = [
    { id: 'update-profile', title: 'Update Profile', icon: <IconChat /> },
    { id: 'vehicle-preference', title: 'Vehicle Preference', icon: <IconInfo /> },
    { id: 'language', title: 'Language Settings', icon: <IconChat /> },
    { id: 'terms-conditions', title: 'Terms & Conditions', icon: <IconText /> },
  ];

  const renderContent = () => {
    switch (selectedPage) {
      case 'update-profile': return <UpdateProfile />;
      case 'vehicle-preference': return <VehiclePreference />;
      case 'language': return <LanguagePreference />;
      case 'terms-conditions': return <TermsAndConditions />;
      default: return <Typography>Select an option</Typography>;
    }
  };

  return (
    <div style={{ display: 'flex', gap: '24px', width: '100%' }}>
      <MenuContainer>
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            isSelected={selectedPage === item.id}
            onClick={() => setSelectedPage(item.id)}
          >
            <span style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {item.icon}
              <Typography variant="subtitle1">{item.title}</Typography>
            </span>
            <IconChevron />
          </MenuItem>
        ))}
      </MenuContainer>

      <ContentContainer>
        <ContentScroll>
          {renderContent()}
        </ContentScroll>
      </ContentContainer>
    </div>
  );
};

export default Settings;