import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { MenuRounded, KeyboardArrowDown, Add, Close } from "@mui/icons-material";
import { TextField, CircularProgress } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import tokenManager from "../utils/tokenManager";
import api from "../utils/axiosInterceptor";


import { 
  Slide
} from '@mui/material';
import { 
  AccessTime
} from '@mui/icons-material';

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  borderBottom: '1px solid #dadce0',
  backgroundColor: '#f8f9fa',
});

const LeftSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
});

const IconButton = styled(Button)({
  minWidth: 'unset',
  padding: '8px',
  borderRadius: '50%',
  backgroundColor: 'white',
  color: 'black',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: 'white',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
});

const YearSelector = styled(Button)({
  border: '1px solid #dadce0',
  borderRadius: '4px',
  padding: '4px 8px',
  textTransform: 'none',
  color: '#3c4043',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
});

const SelectDateButton = styled(Button)({
  backgroundColor: '#efb600',
  color: '#ffffff',
  borderRadius: '4px',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: '#d9a400',
  },
});

// const TimeSlotGrid = styled(Box)({
//   display: 'grid',
//   gridTemplateColumns: 'repeat(4, 1fr)',
//   gap: '8px',
//   marginTop: '16px',
// });

const TimeSlot = styled(Button)(({ selected }) => ({
  padding: '8px',
  border: '1px solid #dadce0',
  borderRadius: '4px',
  backgroundColor: selected ? '#efb600' : 'white',
  color: selected ? 'white' : '#3c4043',
  '&:hover': {
    backgroundColor: selected ? '#d9a400' : 'rgba(0, 0, 0, 0.04)',
  },
}));






// const CustomDialog = styled(Dialog)({
//   '& .MuiDialog-paper': {
//     backgroundColor: '#011c2f',
//     color: 'white',
//     borderRadius: '12px',
//     maxWidth: '500px',
//     width: '100%'
//   }
// });

const CustomButton = styled('button')({
  padding: '12px 24px',
  border: 'none',
  borderRadius: '6px',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: '500',
  transition: 'all 0.2s ease',
});

const PrimaryButton = styled(CustomButton)({
  backgroundColor: '#efb600',
  color: 'white',
  '&:hover': {
    backgroundColor: '#d9a400',
  },
  '&:disabled': {
    backgroundColor: '#565656',
    cursor: 'not-allowed',
  }
});

const SecondaryButton = styled(CustomButton)({
  backgroundColor: 'transparent',
  color: '#fff',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  }
});

// const CustomTimeSlot = styled('button')(({ selected }) => ({
//   padding: '12px',
//   border: '1px solid rgba(255, 255, 255, 0.2)',
//   borderRadius: '6px',
//   backgroundColor: selected ? '#efb600' : 'transparent',
//   color: selected ? '#011c2f' : 'white',
//   cursor: 'pointer',
//   transition: 'all 0.2s ease',
//   '&:hover': {
//     backgroundColor: selected ? '#d9a400' : 'rgba(255, 255, 255, 0.1)',
//     transform: 'translateY(-2px)',
//   }
// }));

// const CustomTextField = styled(TextField)({
//   '& .MuiOutlinedInput-root': {
//     color: 'white',
//     '& fieldset': {
//       borderColor: 'rgba(255, 255, 255, 0.2)',
//     },
//     '&:hover fieldset': {
//       borderColor: 'rgba(255, 255, 255, 0.3)',
//     },
//     '&.Mui-focused fieldset': {
//       borderColor: '#efb600',
//     },
//   },
//   '& .MuiInputLabel-root': {
//     color: 'rgba(255, 255, 255, 0.7)',
//     '&.Mui-focused': {
//       color: '#efb600',
//     },
//   },
// });









const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'linear-gradient(145deg, #011c2f 0%, #03294a 100%)',
    borderRadius: '16px',
    maxWidth: '600px',
    width: '100%'
  }
}));

const TimeSlotGrid = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
  gap: '12px',
  margin: '24px 0'
});

const CustomTimeSlot = styled(Button)(({ selected }) => ({
  padding: '12px',
  borderRadius: '12px',
  backgroundColor: selected ? '#efb600' : 'rgba(255, 255, 255, 0.05)',
  color: selected ? '#011c2f' : 'white',
  border: selected ? 'none' : '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: selected ? '#efb600' : 'rgba(255, 255, 255, 0.1)',
    transform: 'translateY(-2px)'
  }
}));









const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.05)', // Add subtle background
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.3)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#efb600',
    },
    // Style for the calendar icon
    '& .MuiSvgIcon-root': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-focused': {
      color: '#efb600',
    },
  },
  // Style for the calendar popup
  '& .MuiCalendarPicker-root': {
    backgroundColor: '#011c2f',
    color: 'white',
  },
  // Style for the input text
  '& input': {
    color: 'white',
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.5)',
      opacity: 1,
    },
  },
});



const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    }
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiIconButton-root': {
    color: 'white',
  },
  // Calendar popup styles
  '& .MuiPaper-root': {
    backgroundColor: '#011c2f',
    color: 'white',
  },
  '& .MuiCalendarPicker-root': {
    backgroundColor: '#011c2f',
    color: 'white',
  },
  '& .MuiPickersDay-root': {
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    '&.Mui-selected': {
      backgroundColor: '#efb600',
      color: '#011c2f',
    },
  },
  '& .MuiDayPicker-header': {
    color: 'white',
  },
  '& .MuiTypography-root': {
    color: 'white',
  },
}));


function SchedulingHeader({ 
  year, onYearChange, onMenuToggle, onBookingCreated, carId ,
   fetchUserBookings,
   hasActiveBooking
  }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [success, setSuccess] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const open = Boolean(anchorEl);
  const timeSlots = Array.from({ length: 9 }, (_, i) => {
    const hour = i + 9;
    return {
      label: `${hour}:00 ${hour >= 12 ? 'PM' : 'AM'}`,
      value: hour,
    };
  });

  const handleYearClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleYearClose = () => {
    setAnchorEl(null);
  };

  const handleYearSelect = (selectedYear) => {
    onYearChange(selectedYear);
    handleYearClose();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setError(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDate(null);
    setSelectedTime(null);
    setRemark("");
    setError(null);
  };

  const handleDateChange = async (newDate) => {
    try {
      setSelectedDate(newDate);
      setLoading(true);
      setError(null);

      await new Promise(resolve => setTimeout(resolve, 1000));

      const now = new Date();
      const isToday = newDate?.toDateString() === now.toDateString();
      const currentHour = now.getHours();
      const slots = timeSlots.filter(slot => !isToday || slot.value > currentHour);

      setAvailableSlots(slots);
    } catch (err) {
      setError('Failed to fetch available time slots');
    } finally {
      setLoading(false);
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time === selectedTime ? null : time);
  };

  const handleSchedule = async () => {
    if (hasActiveBooking) {
      setError('Please cancel your existing booking before making a new one');
      return;
    }
    if (!selectedDate || !selectedTime) {
      setError('Please select both date and time');
      return;
    }
  
    try {
      setLoading(true);
      setError(null);
  
      const userId = tokenManager.getUserId();
      if (!userId) {
        setError('User not authenticated');
        return;
      }
  
      const bookingDate = new Date(selectedDate);
      bookingDate.setHours(selectedTime.hours || selectedTime);
      bookingDate.setMinutes(0);
  
      const bookingData = {
        date: bookingDate,
        userId,
        carId,
        status: 'pending',
      };
  
      if (remark) {
        bookingData.remark = remark;
      }
  
      const response = await api.post('/booking', bookingData);
  
      if (response.data) {
        setSuccess('Viewing scheduled successfully');
        setSnackbarOpen(true);
  
        // Call all refresh/update functions
        if (onBookingCreated) {
          onBookingCreated(response.data);
        }
        await fetchUserBookings();
        
        setSelectedDate(null);
        setSelectedTime(null);
        handleCloseDialog();
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message || 'Failed to schedule viewing');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 10 }, (_, i) => currentYear + i);

  return (
    <>
      <HeaderContainer>
        <LeftSection>
          <IconButton onClick={onMenuToggle}>
            <MenuRounded />
          </IconButton>
          <YearSelector onClick={handleYearClick} endIcon={<KeyboardArrowDown />}>
            {year}
          </YearSelector>
          <Menu anchorEl={anchorEl} open={open} onClose={handleYearClose}>
            {yearOptions.map((y) => (
              <MenuItem key={y} onClick={() => handleYearSelect(y)}>{y}</MenuItem>
            ))}
          </Menu>
        </LeftSection>
        <SelectDateButton variant="contained" endIcon={<Add />} onClick={handleOpenDialog}>
          Select Viewing Date
        </SelectDateButton>
      </HeaderContainer>

      <CustomDialog 
  open={openDialog} 
  onClose={handleCloseDialog}
  TransitionComponent={Slide}
  TransitionProps={{ direction: "up" }}
>
  <Box sx={{ p: 4 }}>
    {/* Header */}
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      mb: 4,
      pb: 2,
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
    }}>
      <Box>
        <Typography sx={{ 
          fontSize: '28px', 
          fontWeight: '700',
          color: 'white',
          letterSpacing: '-0.5px'
        }}>
          Schedule Viewing
        </Typography>
        <Typography sx={{ 
          color: 'rgba(255, 255, 255, 0.7)',
          mt: 1
        }}>
          Select your preferred date and time
        </Typography>
      </Box>
      <IconButton 
        onClick={handleCloseDialog} 
        sx={{ 
          color: '#011c2f',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: 'white'
          }
        }}
      >
        <Close />
      </IconButton>
    </Box>

    {/* Error Alert */}
    {error && (
      <Alert 
        severity="error" 
        sx={{ 
          mb: 3,
          backgroundColor: 'rgba(211, 47, 47, 0.1)',
          color: '#ff4444',
          border: '1px solid rgba(211, 47, 47, 0.3)',
          '& .MuiAlert-icon': {
            color: '#ff4444'
          }
        }}
      >
        {error}
      </Alert>
    )}

    {/* Date Picker */}
   
    <LocalizationProvider dateAdapter={AdapterDateFns}>
  <StyledDatePicker
    label="Select Date"
    value={selectedDate}
    onChange={handleDateChange}
    minDate={new Date()}
    sx={{
      width: '100%',
      '& .MuiInputLabel-root': {
        color: 'white',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.3)',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#efb600',
      },
    }}
    slotProps={{
      textField: {
        sx: {
          '& .MuiInputLabel-root': {
            color: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.2)',
            },
            '&:hover fieldset': {
              borderColor: 'rgba(255, 255, 255, 0.3)',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#efb600',
            },
          },
        },
      },
    }}
  />
</LocalizationProvider>



    {/* Time Slots */}
    {loading ? (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        my: 4,
        height: '100px'
      }}>
        <CircularProgress sx={{ color: '#efb600' }} />
      </Box>
    ) : selectedDate && availableSlots.length > 0 ? (
      <TimeSlotGrid>
        {availableSlots.map((slot) => (
          <CustomTimeSlot
            key={slot.value}
            selected={selectedTime === slot.value}
            onClick={() => handleTimeSelect(slot.value)}
            startIcon={<AccessTime sx={{ fontSize: 16 }} />}
          >
            {slot.label}
          </CustomTimeSlot>
        ))}
      </TimeSlotGrid>
    ) : selectedDate && (
      <Alert 
        severity="info" 
        sx={{ 
          mt: 3,
          backgroundColor: 'rgba(33, 150, 243, 0.1)',
          color: '#29b6f6',
          border: '1px solid rgba(33, 150, 243, 0.3)',
          '& .MuiAlert-icon': {
            color: '#29b6f6'
          }
        }}
      >
        No available time slots for this date
      </Alert>
    )}

    {/* Remarks TextField */}
    <CustomTextField
      label="Remarks (Optional)"
      variant="outlined"
      fullWidth
      multiline
      rows={3}
      value={remark}
      onChange={(e) => setRemark(e.target.value)}
      sx={{ 
        mt: 3,
        '& .MuiOutlinedInput-root': {
          borderRadius: '12px',
        }
      }}
    />

    {/* Action Buttons */}
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'flex-end', 
      gap: 2,
      mt: 4,
      pt: 3,
      borderTop: '1px solid rgba(255, 255, 255, 0.1)'
    }}>
      <Button
        variant="outlined"
        onClick={handleCloseDialog}
        sx={{
          borderColor: 'rgba(255, 255, 255, 0.2)',
          color: 'white',
          borderRadius: '8px',
          px: 3,
          '&:hover': {
            borderColor: 'rgba(255, 255, 255, 0.3)',
            backgroundColor: 'rgba(255, 255, 255, 0.05)'
          }
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={handleSchedule}
        disabled={!selectedDate || !selectedTime}
        loading={loading}
        sx={{
          backgroundColor: '#efb600',
          color: '#011c2f',
          borderRadius: '8px',
          px: 3,
          '&:hover': {
            backgroundColor: '#ffc107'
          },
          '&:disabled': {
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
            color: 'rgba(255, 255, 255, 0.3)'
          }
        }}
      >
        Place Schedule
      </Button>
    </Box>
  </Box>
</CustomDialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={success ? "success" : "error"} sx={{ width: '100%' }}>
          {success || error}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SchedulingHeader;
