import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Snackbar, Alert, CircularProgress } from '@mui/material';
import api from '../../utils/axiosInterceptor';
import tokenManager from '../../utils/tokenManager';
import ButtonCarLoader from '../ButtonCarLoader';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ProfilePicture = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: ${props => props.src ? `url(${props.src})` : 'url("/listings/frame-1000005178.png")'};
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;

`;

const Heading = styled.h2`
  color: #011c2f;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  align-self: flex-start;
  width: 100%;

`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;

  &::placeholder {
    color: #888;
  }
`;

const FormSection = styled.div`
  width: 100%;
  max-width: 400px;
`;

const Message = styled.div`
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  text-align: center;
  color: ${props => props.type === 'error' ? '#ff0000' : '#008000'};
  background-color: ${props => props.type === 'error' ? '#ffe6e6' : '#e6ffe6'};
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #011c2f;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #022d4a;
  }

`;


const Button2 = styled.button`
  background-color: #011c2f;
  color: white;
  border: none;
  width: 80%;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #022d4a;
  }

`;
export const UpdateProfile = () => {
  // Get user data from localStorage initially
  const userFromStorage = JSON.parse(localStorage.getItem('user') || '{}');
  const [profilePicture, setProfilePicture] = useState(userFromStorage.profilePicture || null);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);



  const [step, setStep] = useState('initial');
  const [formData, setFormData] = useState({
    phone: userFromStorage.phone || '',
    otp: '',
    newPassword: '',
    repeatPassword: '',
  });
  const [resetToken, setResetToken] = useState('');
  
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters';
    }
    if (!/\d/.test(password) || !/[a-zA-Z]/.test(password)) {
      return 'Password must contain both letters and numbers';
    }
    return null;
  };

  const handleProfilePictureUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append('profilePicture', file);
    formData.append('_updateType', 'profilePicture');
  
    try {
      setIsProfileLoading(true);
      const userId = userFromStorage.id;
      const response = await api.patch(`/users/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      setProfilePicture(response.data.profilePicture);
      const updatedUser = { ...userFromStorage, profilePicture: response.data.profilePicture };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      
      showSnackbar('Profile picture updated successfully');
    } catch (error) {
      showSnackbar(error.response?.data?.message || 'Failed to upload profile picture', 'error');
    } finally {
      setIsProfileLoading(false);
    }
  };
  
  const handleRequestOTP = async () => {
    try {
      setIsLoading(true);
      await api.post('/auth/send-password-reset-otp', {
        phone: formData.phone
      });
      setStep('otp');
      showSnackbar('OTP sent successfully');
    } catch (error) {
      showSnackbar(error.response?.data?.message || 'Failed to send OTP', 'error');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleVerifyOTP = async () => {
    try {
      setIsLoading(true);
      const response = await api.post('/auth/verify-password-reset-otp', {
        phone: formData.phone,
        otp: formData.otp
      });
      setResetToken(response.data.token);
      setStep('changePassword');
      showSnackbar('OTP verified successfully');
    } catch (error) {
      showSnackbar(error.response?.data?.message || 'Invalid OTP', 'error');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleChangePassword = async () => {
    const passwordError = validatePassword(formData.newPassword);
    if (passwordError) {
      showSnackbar(passwordError, 'error');
      return;
    }
  
    if (formData.newPassword !== formData.repeatPassword) {
      showSnackbar('Passwords do not match', 'error');
      return;
    }
  
    try {
      setIsPasswordLoading(true);
      await api.post('/auth/reset-password', {
        token: resetToken,
        password: formData.newPassword
      });
      showSnackbar('Password changed successfully');
      setStep('initial');
      setFormData({
        phone: userFromStorage.phone || '',
        otp: '',
        newPassword: '',
        repeatPassword: '',
      });
    } catch (error) {
      showSnackbar(error.response?.data?.message || 'Failed to change password', 'error');
    } finally {
      setIsPasswordLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 'initial':
        return (
          <>
            <Input
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
            />
           <Button 
  onClick={handleRequestOTP}
  disabled={isLoading || !formData.phone}
>
  {isLoading ? (
    <ButtonCarLoader text="Sending..." />
  ) : (
    'Request OTP'
  )}
</Button>
          </>
        );

      case 'otp':
        return (
          <>
            <Input
              type="text"
              name="otp"
              placeholder="Enter OTP"
              value={formData.otp}
              onChange={handleInputChange}
            />
           <Button 
  onClick={handleVerifyOTP}
  disabled={isLoading || !formData.otp}
>
  {isLoading ? (
    <ButtonCarLoader text="Verifying..." />
  ) : (
    'Verify OTP'
  )}
</Button>
          </>
        );

        case 'changePassword':
          return (
            <>
              <Input
                type="password"
                name="newPassword"
                placeholder="New Password"
                value={formData.newPassword}
                onChange={handleInputChange}
              />
              <Input
                type="password"
                name="repeatPassword"
                placeholder="Repeat Password"
                value={formData.repeatPassword}
                onChange={handleInputChange}
              />
             <Button 
              onClick={handleChangePassword}
              disabled={isPasswordLoading || !formData.newPassword || !formData.repeatPassword}
            >
              {isPasswordLoading ? (
                <ButtonCarLoader text="Updating..." />
              ) : (
                'Update Password'
              )}
            </Button>
            </>
          );
    }
  };

  return (
    <Frame>
      <ProfilePicture src={profilePicture} />
      <input
        type="file"
        id="profilePicture"
        hidden
        onChange={handleProfilePictureUpload}
        accept="image/*"
      />
     <Button2
        as="label" 
        htmlFor="profilePicture"
        disabled={isProfileLoading}
      >
        {isProfileLoading ? (
          <ButtonCarLoader text="Uploading..." />
        ) : (
          'Upload Profile Picture'
        )}
      </Button2>

      
      <FormSection>
        <Heading>Change Your Password</Heading>
        {renderStepContent()}
      </FormSection>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Frame>
  );
};

export default UpdateProfile;