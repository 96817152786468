import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import UpdateProfile from '../components/settings/UpdateProfile';
import VehiclePreference from '../components/settings/VehiclePreference';
import LanguagePreference from '../components/settings/LanguagePreference';
import TermsAndConditions from '../components/settings/ndConidtion';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AccessTime, ChevronRight } from '@mui/icons-material';


  

const DiscountBadge = styled('div')({
  alignSelf: 'flex-end',
  backgroundColor: '#149B09',
  borderRadius: '10px',
  padding: '10px',
  '& .text': {
    color: '#ffffff',
    fontSize: '20px',
    fontWeight: 600,
  }
});


const OfferButton = styled('button')({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  padding: '15px 20px',
  border: 'none',
  boxShadow: '0px 3px 15px rgba(0,0,0,0.15)',
  fontSize: '24px',
  fontWeight: 600,
  color: '#011C2F',
  cursor: 'pointer',
});

const NavigationSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  width: '100%',
});

const NextButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  backgroundColor: '#011C2F',
  color: '#ffffff',
  borderRadius: '10px',
  padding: '20px',
  border: 'none',
  width: '100%',
  cursor: 'pointer',
  fontSize: '18px',
});

const Pagination = styled('div')({
  display: 'flex',
  gap: '50px',
  justifyContent: 'center',
});

const PaginationDot = styled('div')(({ active }) => ({
  width: '42.86px',
  height: '42.86px',
  borderRadius: '85.71px',
  backgroundColor: active ? '#011C2F' : '#D7D7D7',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: active ? '#ffffff' : '#000000',
  fontSize: '15.4px',
  fontWeight: 500,
}));





const ThumbnailGrid = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    gap: '10px'
  });
  

  
  const ThumbnailImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '12px'
  });



 
  
  const SellerSection = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  });
  
  const SellerInfo = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  });
  
  const SellerAvatar = styled('img')({
    width: '50px',
    height: '50px',
    borderRadius: '100px'
  });
  
  const SellerName = styled(Typography)({
    color: '#011C2F',
    fontSize: '24px',
    fontWeight: 500,
    fontFamily: '"SF Pro Text-Medium", Helvetica'
  });
  
  const VerifiedBadge = styled(Typography)({
    color: '#149B09',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: '"SF Pro Text-Medium", Helvetica'
  });
  
 
  
  const CarTitle = styled(Typography)({
    color: '#011C2F',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: '"SF Pro Text-Semibold", Helvetica'
  });
  
  const DescriptionTitle = styled(Typography)({
    color: '#011C2F',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: '"SF Pro Text-Medium", Helvetica'
  });
  
 
  
  const ViewingButton = styled('button')({
    backgroundColor: '#011C2F',
    color: '#FFFFFF',
    borderRadius: '10px',
    padding: '15px',
    width: '100%',
    border: 'none',
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: '"SF Pro Text-Semibold", Helvetica',
    boxShadow: '0px 3px 15px rgba(0,0,0,0.15)',
    cursor: 'pointer',
    margin: '10px 0'
  });
  
  const AdSection = styled('div')({
    backgroundColor: '#011C2F',
    border: '1px solid #D9D9D9',
    borderRadius: '20px',
    padding: '20px',
    display: 'flex',
    gap: '20px',
    width: '100%'
  });
  
  const AdImage = styled('img')({
    width: '100px',
    height: '100px',
    borderRadius: '10px',
    objectFit: 'cover'
  });
  
  const AdContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    flex: 1
  });
  
  const AdTitle = styled(Typography)({
    color: '#FFFFFF',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: '"Poppins-Medium", Helvetica'
  });
  
  const AdText = styled(Typography)({
    color: '#FFFFFF',
    fontSize: '16px',
    letterSpacing: '-0.15px',
    lineHeight: '24px',
    fontFamily: '"SF Pro Text-Regular", Helvetica'
  });







const ProductCard = styled('div')(({ theme }) => ({
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#F0F0F0',
  border: '1px solid #D9D9D9',
  borderRadius: '20px',
  padding: '20px',
  gap: '15px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

// const Container = styled('div')({
//   backgroundColor: '#F0F0F0',
//   border: '1px solid #D9D9D9',
//   borderRadius: '20px',
//   width: '50%',
//   padding: '20px',
//   display: 'flex',
//   flexDirection: 'column',
//   gap: '15px',
// });
  



// Adjust ImageContainer height
const ImageContainer = styled('img')({
  width: '100%',
  height: '250px', 
  objectFit: 'cover',
  borderRadius: '10px',
});

// Modify ThumbnailWrapper size
const ThumbnailWrapper = styled('div')({
  position: 'relative',
  width: '80px', 
  height: '80px', 
  borderRadius: '8px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.9,
    transform: 'scale(1.02)',
    transition: 'all 0.2s ease-in-out'
  }
});






// Modify DetailsContainer
const DetailsContainer = styled('div')({
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  padding: '20px', 
  display: 'flex',
  flexDirection: 'column',
  gap: '15px', 
  flex: 1,
  width: '100%',
});

const Title = styled(Typography)({
  color: '#011C2F',
  fontSize: '24px', 
  fontWeight: 600,
  fontFamily: '"SF Pro Text-Semibold", Helvetica'
});

const DescriptionText = styled(Typography)({
  color: '#454A53',
  fontSize: '14px', 
  letterSpacing: '-0.15px',
  lineHeight: '20px', 
  fontFamily: '"SF Pro Text-Regular", Helvetica'
});

// Modify PriceSection
const PriceSection = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#D9D9D9',
  borderRadius: '12px',
  padding: '15px', // Reduced padding
  width: '100%',
});const OrderCard = styled('div')({
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
  padding: '15px',
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F5F5F5',
  }
});

const OrderImage = styled('img')({
  width: '80px',
  height: '80px',
  borderRadius: '10px',
  objectFit: 'cover'
});

const OrderDetails = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '5px'
});

const ScheduleDate = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  color: '#666',
  fontSize: '14px'
});

const CarName = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  color: '#011C2F'
});

const CarModel = styled(Typography)({
  fontSize: '14px',
  color: '#666'
});

const Price = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  color: '#011C2F'
});














const Container = styled('div')({
  backgroundColor: '#F0F0F0',
  borderRadius: '20px',
  padding: '20px',
  width: '50%',
});

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  marginBottom: '20px',
});

const CarImage = styled('div')({
  width: '40px',
  height: '40px',
  backgroundColor: '#D9D9D9',
  borderRadius: '8px',
  backgroundImage: 'url("/home/rectangle-9-2.png")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
});

const TitleSection = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
});


const StatusBadge = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '4px',
});

const Toggle = styled('div')({
  width: '53px',
  height: '31px',
  backgroundColor: '#34A853',
  borderRadius: '133px',
  position: 'relative',
  cursor: 'pointer',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '23px',
    height: '23px',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    top: '4px',
    left: '27px',
    transition: 'left 0.2s',
  }
});

const StatusText = styled('span')({
  color: '#34A853',
  fontSize: '13.3px',
  fontWeight: 400,
});

const Description = styled('p')({
  color: '#464A54',
  fontSize: '14px',
  marginBottom: '20px',
});

const FormField = styled('div')({
  marginBottom: '15px',
});

const Label = styled('div')({
  color: '#011C2F',
  fontSize: '16px',
  fontWeight: 600,
  marginBottom: '5px',
});

const Input = styled('input')({
  width: '100%',
  padding: '15px 20px',
  border: '1px solid #D9D9D9',
  borderRadius: '10px',
  fontSize: '18px',
  color: '#011C2F',
  '&::placeholder': {
    color: '#011C2F',
  },
  '&:focus': {
    outline: 'none',
    borderColor: '#011C2F',
  }
});

const Select = styled('div')({
  width: '100%',
  padding: '15px 20px',
  border: '1px solid #D9D9D9',
  borderRadius: '10px',
  fontSize: '13px',
  color: '#7C7C7C',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});



const MyStore = () => {
  const [selectedPage, setSelectedPage] = useState('update-profile');



  const [selectedImage, setSelectedImage] = useState(0);


  const orderData = [
    {
      id: 1,
      image: '/home/rectangle-9-2.png',
      scheduleDate: '30/12/2023',
      name: '2005 Blue Beauty',
      model: 'Honda Civic',
      price: '3.4M ETB'
    },
    {
      id: 2,
      image: '/home/rectangle-9-3.png',
      scheduleDate: '30/12/2023',
      name: '2005 Blue Beauty',
      model: 'Honda Civic',
      price: '3.4M ETB'
    }
  ];

  const productImages = [
    '/home/rectangle-9-2.png',
    '/home/rectangle-9-3.png',
    '/home/rectangle-9.png',
    '/background1.jpeg',
  ];

  const handleThumbnailClick = (index) => {
    setSelectedImage(index);
  };

  const renderContent = () => {
    switch (selectedPage) {
      case 'update-profile': return <UpdateProfile />;
      case 'vehicle-preference': return <VehiclePreference />;
      case 'language': return <LanguagePreference />;
      case 'terms-conditions': return <TermsAndConditions />;
      default: return <Typography>Select an option</Typography>;
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      gap: '20px', 
      width: '100%',
      padding: '20px',
      paddingTop: '0',
    }}>
      <ProductCard>
      <div style={{  paddingTop: '0' }}>
      <Title>My Store</Title>
      <ViewingButton>Add New Vehicle</ViewingButton>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Lorem ipsum dolor sit amet. 33 excepturi sint et reiciendis iure vel 
        aperiam tenetur id alias molestiaem.
      </Typography>
      
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {orderData.map((order) => (
          <OrderCard key={order.id}>
            <OrderImage src={order.image} alt={order.name} />
            <OrderDetails>
              <ScheduleDate>
                <AccessTime sx={{ fontSize: 16 }} />
                Schedule Date: {order.scheduleDate}
              </ScheduleDate>
              <CarName>{order.name}</CarName>
              <CarModel>{order.model}</CarModel>
            </OrderDetails>
            <Price>{order.price}</Price>
            <ChevronRight />
          </OrderCard>
        ))}
      </div>
    </div>
      </ProductCard>

      <Container>
    <Title>Edit Listing</Title>

    
    <Header>
      <CarImage />
      <TitleSection>
        <Title>Chevrolet: 2023 Cruze LT Hatchback</Title>
        <StatusBadge>
          <Toggle />
          <StatusText>In Stock</StatusText>
        </StatusBadge>
      </TitleSection>
    </Header>

    <Description>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida eget 
      ultricies pharetra scelerisque duis cursus.
    </Description>

    {[...Array(7)].map((_, index) => (
      <FormField key={index}>
        <Label>Vehicle Brand</Label>
        <Input defaultValue="Toyota" />
      </FormField>
    ))}

    <FormField>
      <Label>Vehicle Condition</Label>
      <Select>
        <span>Select condition type</span>
        <ChevronRight />
      </Select>
    </FormField>

    <FormField>
      <Label>Vehicle Image</Label>
      {/* Add image upload functionality here */}
    </FormField>
  </Container>
    </div>
  );
};
export default MyStore;