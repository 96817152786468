import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';






const LanguageOption = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  }));
  
  const FlagAndName = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginLeft: '20px',
    flexGrow: 1,
  }));
  
  const FlagIcon = styled(Box)(({ theme }) => ({
    width: '50px',
    height: '37px',
    '& > svg': {
      width: '100%',
      height: '100%',
    },
  }));
  
  const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: 0,
    '& .MuiFormControlLabel-label': {
      flexGrow: 1,
    },
  }));














const Frame = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: '#f0f0f0',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  padding: '50px 30px',
  width: '100%',
  maxWidth: '1400px',
  margin: '0 auto',
}));

const Title = styled(Typography)(({ theme }) => ({
  color: '#011c2f',
  fontFamily: '"SF Pro Text-Semibold", Helvetica',
  fontSize: '32px',
  fontWeight: 600,
  marginBottom: '30px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#011c2f',
  color: '#ffffff',
  fontFamily: '"SF Pro Text-Semibold", Helvetica',
  fontSize: '20px',
  borderRadius: '8px',
  padding: '20px 40px',
  cursor: 'pointer',
  boxShadow: '0px 7px 10px rgba(33, 33, 33, 0.15)',
  '&:hover': {
    backgroundColor: '#022d4a',
  },
}));

const UnitedStatesOfAmerica = () => (
  <svg fill="none" height="37" viewBox="0 0 50 37" width="50" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_0_44)">
      <g clipPath="url(#clip1_0_44)">
        <rect fill="white" height="36.3636" rx="4.54545" width="50" />
        <path clipRule="evenodd" d="M0 0H21.4286V16.9697H0V0Z" fill="#1A47B8" fillRule="evenodd" />
        <path clipRule="evenodd" d="M21.4286 0V2.42424H50V0H21.4286ZM21.4286 4.84848V7.27273H50V4.84848H21.4286ZM21.4286 9.69697V12.1212H50V9.69697H21.4286ZM21.4286 14.5455V16.9697H50V14.5455H21.4286ZM0 19.3939V21.8182H50V19.3939H0ZM0 24.2424V26.6667H50V24.2424H0ZM0 29.0909V31.5152H50V29.0909H0ZM0 33.9394V36.3636H50V33.9394H0Z" fill="#F93939" fillRule="evenodd" />
        <path clipRule="evenodd" d="M2.38147 2.42419V4.84844H4.76242V2.42419H2.38147ZM7.14337 2.42419V4.84844H9.52433V2.42419H7.14337ZM11.9053 2.42419V4.84844H14.2862V2.42419H11.9053ZM16.6672 2.42419V4.84844H19.0481V2.42419H16.6672ZM14.2862 4.84844V7.27268H16.6672V4.84844H14.2862ZM9.52433 4.84844V7.27268H11.9053V4.84844H9.52433ZM4.76242 4.84844V7.27268H7.14337V4.84844H4.76242ZM2.38147 7.27268V9.69692H4.76242V7.27268H2.38147ZM7.14337 7.27268V9.69692H9.52433V7.27268H7.14337ZM11.9053 7.27268V9.69692H14.2862V7.27268H11.9053ZM16.6672 7.27268V9.69692H19.0481V7.27268H16.6672ZM2.38147 12.1212V14.5454H4.76242V12.1212H2.38147ZM7.14337 12.1212V14.5454H9.52433V12.1212H7.14337ZM11.9053 12.1212V14.5454H14.2862V12.1212H11.9053ZM16.6672 12.1212V14.5454H19.0481V12.1212H16.6672ZM14.2862 9.69692V12.1212H16.6672V9.69692H14.2862ZM9.52433 9.69692V12.1212H11.9053V9.69692H9.52433ZM4.76242 9.69692V12.1212H7.14337V9.69692H4.76242Z" fill="white" fillRule="evenodd" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_0_44">
        <rect fill="white" height="36.3636" width="50" />
      </clipPath>
      <clipPath id="clip1_0_44">
        <rect fill="white" height="36.3636" rx="4.54545" width="50" />
      </clipPath>
    </defs>
  </svg>
);

const Ethiopia = () => (
  <svg fill="none" height="37" viewBox="0 0 50 37" width="50" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_0_57)">
      <g clipPath="url(#clip1_0_57)">
        <path d="M45.2381 0H4.7619C2.13198 0 0 2.17074 0 4.84848V31.5152C0 34.1929 2.13198 36.3636 4.7619 36.3636H45.2381C47.868 36.3636 50 34.1929 50 31.5152V4.84848C50 2.17074 47.868 0 45.2381 0Z" fill="#FFDA2C" />
        <path clipRule="evenodd" d="M0 24.2423H50V36.3635H0V24.2423Z" fill="#F93939" fillRule="evenodd" />
        <path clipRule="evenodd" d="M0 0H50V12.1212H0V0Z" fill="#249F58" fillRule="evenodd" />
        <path d="M24.9998 29.0908C30.9172 29.0908 35.7141 24.2066 35.7141 18.1817C35.7141 12.1567 30.9172 7.27258 24.9998 7.27258C19.0825 7.27258 14.2856 12.1567 14.2856 18.1817C14.2856 24.2066 19.0825 29.0908 24.9998 29.0908Z" fill="#1A47B8" />
        <path clipRule="evenodd" d="M24.9956 21.2121L21.4956 23.086L22.1623 19.1176L19.3337 16.3054L23.2456 15.7309L24.9956 12.1188L26.7432 15.7309L30.6551 16.3054L27.8218 19.1176L28.4932 23.0836" fill="#FFDA2C" fillRule="evenodd" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_0_57">
        <rect fill="white" height="36.3636" width="50" />
      </clipPath>
      <clipPath id="clip1_0_57">
        <rect fill="white" height="36.3636" rx="4.54545" width="50" />
      </clipPath>
    </defs>
  </svg>
);

const Spain = () => (
  <svg fill="none" height="37" viewBox="0 0 50 37" width="50" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_0_71)">
      <g clipPath="url(#clip1_0_71)">
        <rect fill="#F93939" height="36.3636" rx="4.54545" width="50" />
        <path d="M45.2381 0H4.7619C2.13198 0 0 2.17074 0 4.84848V31.5152C0 34.1929 2.13198 36.3636 4.7619 36.3636H45.2381C47.868 36.3636 50 34.1929 50 31.5152V4.84848C50 2.17074 47.868 0 45.2381 0Z" fill="#F93939" />
        <path clipRule="evenodd" d="M0 9.69714H50V26.6668H0V9.69714Z" fill="#FFDA2C" fillRule="evenodd" />
        <path clipRule="evenodd" d="M21.4285 15.0837V21.1928C21.4285 22.8897 19.8285 24.2473 17.857 24.2473H13.0951C11.1285 24.2425 9.52371 22.8776 9.52371 21.1879V15.0788C9.52371 13.6922 10.5904 12.5334 12.057 12.1576C12.4999 10.897 13.8618 12.0267 15.4761 12.0267C17.0999 12.0267 18.4523 10.9043 18.8951 12.16C20.357 12.5455 21.4285 13.7067 21.4285 15.0837Z" fill="#D4AF2C" fillRule="evenodd" />
        <path clipRule="evenodd" d="M21.4291 16.9697H23.81V24.2425H21.4291V16.9697ZM7.14334 16.9697H9.52429V24.2425H7.14334V16.9697Z" fill="#CBCBCB" fillRule="evenodd" />
        <path clipRule="evenodd" d="M21.4291 21.8181H23.81V24.2424H21.4291V21.8181ZM7.14334 21.8181H9.52429V24.2424H7.14334V21.8181Z" fill="#1A47B8" fillRule="evenodd" />
        <path clipRule="evenodd" d="M21.4291 14.5454H23.81V16.9697H21.4291V14.5454ZM7.14334 14.5454H9.52429V16.9697H7.14334V14.5454Z" fill="#D4AF2C" fillRule="evenodd" />
        <path clipRule="evenodd" d="M11.9052 14.5454H14.2861V18.1818H11.9052V14.5454ZM16.6671 19.3939H19.048V23.0303H16.6671V19.3939Z" fill="#AF010D" fillRule="evenodd" />
        <path clipRule="evenodd" d="M16.667 14.5454H19.048V18.1818H16.667V14.5454Z" fill="#AE6A3E" fillRule="evenodd" />
        <path clipRule="evenodd" d="M11.9052 19.3938H14.2861V23.0302H11.9052V19.3938Z" fill="#FFDA2C" fillRule="evenodd" />
        <path clipRule="evenodd" d="M14.2861 14.5456L11.9052 12.1213H19.048L16.6671 14.5456H14.2861Z" fill="#AF010D" fillRule="evenodd" />
        <path clipRule="evenodd" d="M14.2856 9.69714H16.6665V12.1214H14.2856V9.69714Z" fill="#D4AF2C" fillRule="evenodd" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_0_71">
        <rect fill="white" height="36.3636" width="50" />
      </clipPath>
      <clipPath id="clip1_0_71">
        <rect fill="white" height="36.3636" rx="4.54545" width="50" />
      </clipPath>
    </defs>
  </svg>
);
const LanguagePreference = () => {
    const [selectedLanguage, setSelectedLanguage] = React.useState('english');
  
    const handleLanguageChange = (event) => {
      setSelectedLanguage(event.target.value);
    };
  
    return (
      <Frame>
        <Title>Select Preferred Language</Title>
        <RadioGroup
          aria-label="language"
          name="language"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          <LanguageOption>
            <StyledFormControlLabel
              value="english"
              control={<Radio />}
              label={
                <FlagAndName>
                  <FlagIcon>
                    <UnitedStatesOfAmerica />
                  </FlagIcon>
                  English
                </FlagAndName>
              }
            />
          </LanguageOption>
          <LanguageOption>
            <StyledFormControlLabel
              value="amharic"
              control={<Radio />}
              label={
                <FlagAndName>
                  <FlagIcon>
                    <Ethiopia />
                  </FlagIcon>
                  አማርኛ
                </FlagAndName>
              }
            />
          </LanguageOption>
          <LanguageOption>
            <StyledFormControlLabel
              value="spanish"
              control={<Radio />}
              label={
                <FlagAndName>
                  <FlagIcon>
                    <Spain />
                  </FlagIcon>
                  Spanish
                </FlagAndName>
              }
            />
          </LanguageOption>
        </RadioGroup>
        <StyledButton variant="contained">
          Set Language Preference
        </StyledButton>
      </Frame>
    );
  };
  
  export default LanguagePreference;