import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Link, Box, Typography, Container, Grid, CircularProgress, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import LogoContent from './LogoContent';
import api from '../utils/axiosInterceptor';

const SignupSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string().required('Required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').required('Required'),
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '8px',
  },
});

const StyledButton = styled(Button)({
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '8px',
  padding: '10px 0',
  '&:hover': {
    backgroundColor: '#333',
  },
});

const StyledPhoneInput = styled(PhoneInput)({
  '& .react-tel-input .form-control': {
    width: '100%',
    height: '56px',
    borderRadius: '8px',
    fontSize: '16px',
  },
});

const SignUp = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await api.post('/auth/register', {
        fullName: values.fullName,
        email: values.email,
        phone: values.phone,
        password: values.password,
      });

      setSnackbarSeverity('success');
      setSnackbarMessage('Sign up successful! Please verify your phone number.');
      setOpenSnackbar(true);

      setTimeout(() => {
        navigate('/verify-otp', { state: { phone: values.phone } });
      }, 1500);
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage(error.response?.data?.message || 'Sign up failed. Please try again.');
      setOpenSnackbar(true);
      console.error('Sign up failed:', error);
    }
    setSubmitting(false);
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(/cover.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          {/* Logo Section */}
          <Grid item xs={12} sm={6} display={{ xs: 'none', sm: 'block' }}>
            <LogoContent />
          </Grid>

          {/* Sign-Up Form Section */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '16px',
                padding: { xs: '20px', sm: '40px' },
                backdropFilter: 'blur(10px)',
                maxWidth: '400px',
                margin: { xs: '0 auto', sm: '0 0 0 auto' },
              }}
            >
              <Typography variant="h4" component="h1" sx={{ mb: 1, fontWeight: 'bold' }}>
                Sign Up
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Join Mekina Gebeya today
              </Typography>
              <Formik
                initialValues={{ fullName: '', email: '', phone: '', password: '' }}
                validationSchema={SignupSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, isSubmitting, setFieldValue }) => (
                  <Form>
                    <Field
                      as={StyledTextField}
                      name="fullName"
                      placeholder="Full Name"
                      fullWidth
                      margin="normal"
                      error={touched.fullName && errors.fullName}
                      helperText={touched.fullName && errors.fullName}
                    />
                    <Field
                      as={StyledTextField}
                      name="email"
                      type="email"
                      placeholder="Email"
                      fullWidth
                      margin="normal"
                      error={touched.email && errors.email}
                      helperText={touched.email && errors.email}
                    />
                    <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
                      Enter your phone number
                    </Typography>
                    <StyledPhoneInput
                      country={'et'}
                      value={phone}
                      onChange={phone => {
                        setPhone(phone);
                        setFieldValue('phone', '+' + phone);
                      }}
                      inputProps={{
                        name: 'phone',
                        required: true,
                      }}
                    />
                    {touched.phone && errors.phone && (
                      <Typography color="error" variant="caption">
                        {errors.phone}
                      </Typography>
                    )}
                    <Field
                      as={StyledTextField}
                      name="password"
                      type="password"
                      placeholder="Password"
                      fullWidth
                      margin="normal"
                      error={touched.password && errors.password}
                      helperText={touched.password && errors.password}
                    />
                    <StyledButton
                      type="submit"
                      fullWidth
                      disabled={isSubmitting}
                      sx={{ mt: 2 }}
                    >
                      {isSubmitting ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Sign Up'}
                    </StyledButton>
                  </Form>
                )}
              </Formik>
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography variant="body2">
                  Already have an account?{' '}
                  <Link component={RouterLink} to="/login" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Log in
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SignUp;
