import React from "react";

export const Whatsapp = ({ className }) => {
  return (
    <svg
      className={`whatsapp ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M24.299 5.68854C23.0856 4.47451 21.644 3.51249 20.0572 2.85784C18.4705 2.2032 16.77 1.86886 15.0535 1.87408C7.84997 1.87408 1.98591 7.70885 1.98298 14.8819C1.9798 17.166 2.58187 19.4101 3.72791 21.3858L1.87341 28.1241L8.80212 26.3153C10.72 27.3543 12.867 27.8981 15.0482 27.8973H15.0535C22.2564 27.8973 28.1199 22.062 28.1234 14.8895C28.1278 13.1789 27.7919 11.4845 27.1353 9.90491C26.4788 8.32531 25.5146 6.89205 24.299 5.68854ZM15.0535 25.7018H15.0488C13.1043 25.7024 11.1952 25.182 9.5199 24.1948L9.12322 23.9604L5.01169 25.0339L6.10915 21.0442L5.85076 20.634C4.76348 18.9127 4.18743 16.9179 4.18962 14.8819C4.18962 8.92115 9.06521 4.07135 15.0576 4.07135C17.9322 4.06622 20.6911 5.20311 22.7276 7.23197C24.764 9.26083 25.9112 12.0155 25.9168 14.8901C25.9144 20.8514 21.0412 25.7018 15.0535 25.7018ZM21.0119 17.6047C20.6855 17.4419 19.0783 16.6555 18.7806 16.5471C18.483 16.4387 18.2633 16.3842 18.0459 16.71C17.8285 17.0358 17.2021 17.7647 17.0117 17.9844C16.8213 18.2042 16.6308 18.2282 16.3045 18.0653C15.9781 17.9024 14.9252 17.5596 13.6777 16.4522C12.7068 15.5903 12.0517 14.5262 11.8613 14.201C11.6709 13.8758 11.8408 13.6995 12.0043 13.5378C12.1513 13.3919 12.3306 13.1581 12.4941 12.9682C12.6576 12.7784 12.7121 12.6424 12.8205 12.4256C12.9289 12.2088 12.875 12.019 12.7935 11.8567C12.7121 11.6944 12.0588 10.0942 11.7869 9.44322C11.5215 8.80924 11.2525 8.89537 11.0521 8.88541C10.8617 8.87604 10.642 8.87369 10.4258 8.87369C10.2605 8.878 10.0978 8.91633 9.94803 8.98629C9.79822 9.05626 9.66445 9.15635 9.55505 9.28033C9.25564 9.60612 8.41189 10.3936 8.41189 11.9921C8.41189 13.5905 9.58376 15.1374 9.74548 15.3542C9.9072 15.571 12.0482 18.854 15.3242 20.262C15.9325 20.5225 16.5541 20.7509 17.1863 20.9464C17.9685 21.1936 18.6804 21.159 19.2429 21.0753C19.8705 20.9821 21.1765 20.2889 21.4478 19.5296C21.7191 18.7702 21.7197 18.1198 21.6383 17.9844C21.5568 17.8491 21.3388 17.7671 21.0119 17.6047Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
};






